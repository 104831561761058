import * as Sentry from '@sentry/browser';
import createCustomError from "./createCustomError";
import { HttpErrorResponse } from "@angular/common/http";

type ReportSentryErrorType = {
    error: HttpErrorResponse | unknown;
    customErrorName: string;
    endpointName?: string;
    fingerprint?: string[];
    extras?: ((scope: Sentry.Scope) => void)[];
};

const excludedStatusCodes = [403, 401, 429];

const reportSentryError = ({ error, customErrorName, endpointName, fingerprint, extras }: ReportSentryErrorType) => {
    if(!(error instanceof HttpErrorResponse)) {
      Sentry.captureException(error);
      throw error;
    }

    if(excludedStatusCodes.includes(error.status)) {
      throw error;
    }

    const customError = createCustomError(customErrorName, error.message);

    Sentry.withScope((scope) => {
        endpointName && scope.setTag('feature', endpointName);
        scope.setExtra('responseBody', error.error);
        fingerprint && scope.setFingerprint(fingerprint);
        if (extras) {
            extras.forEach((extra) => {
                extra(scope);
            });
        }
        Sentry.captureException(customError);
    });
};

export default reportSentryError;
