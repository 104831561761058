import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../helpers/constants';

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [CommonModule, MatSelectModule],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent implements OnInit {
  languages = languages;

  selectedLanguage = this.languages[0].code;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslateService);

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const lang = params['lang'];
      if (lang) {
        this.selectedLanguage = lang;
        this.translate.use(lang);
      }
    });
  }

  onLanguageChange(event: MatSelectChange) {
    const selectedLang = event.value;
    this.translate.use(selectedLang);
    this.router.navigate([selectedLang, ...this.router.url.split('/').slice(2)]);
  }
}
