import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  INFO_MODAL_TYPES,
  InfoModalComponent,
  InfoModalData,
  ModalModule,
  ModalPortalComponent,
  ModalService,
} from '@circlek-global/lds-components';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [ModalModule, ModalPortalComponent],
  templateUrl: './modal-base.component.html',
})
export class ModalBaseComponent {
  private modalService = inject(ModalService);
  private translate = inject(TranslateService);

  @Input() modalType!: INFO_MODAL_TYPES;
  @Input() titleTransKey!: string;
  @Input() infoTextTransKey!: string;
  @Input() confirmBtnTextTransKey!: string;
  @Input() cancelBtnTextTransKey!: string;
  @Input() isCancelBtnHidden!: boolean;

  @Output() onButtonClose = new EventEmitter();
  @Output() onButtonConfirm = new EventEmitter();

  openModal() {
    this.modalService
      .open<InfoModalComponent, InfoModalData>(InfoModalComponent, {
        data: {
          type: this.modalType,
          title: this.translate.instant(this.titleTransKey),
          infoText: this.translate.instant(this.infoTextTransKey),
          confirmBtnText: this.translate.instant(this.confirmBtnTextTransKey),
          cancelBtnText: this.translate.instant(this.cancelBtnTextTransKey),
          isCancelBtnHidden: this.isCancelBtnHidden,
        },
      })
      .onClose$.subscribe((decision) => {
        if (decision.data == true) {
          this.onButtonConfirm.emit();
        } else {
          this.onButtonClose.emit();
        }
      });
  }
}
