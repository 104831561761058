import { Routes } from '@angular/router';
import { canActivateAuth } from './auth/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

export const routes: Routes = [
  {
    path: ':lang/profile',
    component: UserProfileComponent,
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/profile/change-data',
    loadComponent: () =>
      import('./change-profile-data/change-profile-data.component').then((c) => c.ChangeProfileDataComponent),
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/profile/change-password',
    loadComponent: () => import('./change-password/change-password.component').then((m) => m.ChangePasswordComponent),
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/profile/confirm-data',
    loadComponent: () =>
      import('./confirm-user-profile/confirm-user-profile.component').then((m) => m.ConfirmUserProfileComponent),
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/terms',
    loadComponent: () => import('./user-terms/user-terms.component').then((m) => m.UserTermsComponent),
    canActivate: [canActivateAuth],
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
];
