<app-modal
  [modalType]="INFO_MODAL_TYPES.INFO"
  [titleTransKey]="'CONFIRM_PROFILE_MODAL_TITLE'"
  [infoTextTransKey]="'CONFIRM_PROFILE_MODAL_TEXT'"
  [confirmBtnTextTransKey]="'CONFIRM_PROFILE_MODAL_CONFIRM_BUTTON'"
  [cancelBtnTextTransKey]="'CONFIRM_PROFILE_MODAL_CANCEL_BUTTON'"
  [isCancelBtnHidden]="false"
  (onButtonClose)="onButtonClose()"
  (onButtonConfirm)="onButtonConfirm()"
/>
@if (error()) {
  <div class="container mx-auto max-w-xl mt-32 mb-32">
    <h1>{{ 'SOMETHING_WENT_WRONG' | translate }}</h1>
    <lds-button (click)="tryAgain()">
      {{ 'TRY_AGAIN' | translate }}
    </lds-button>
  </div>
}
@if (!error() && !loader.isLoading()) {
  <div class="container mx-auto max-w-xl mt-32 mb-32">
    <div class="grid justify-center">
      <h2 class="text-display-lg mb-12">{{ 'PERSONAL_DATA_TITLE' | translate }}</h2>
      <div class="mb-8">
        @if (region === 'eu') {
          <p class="mb-4">
            <strong>
              {{ 'FIRST_NAME_AND_SURNAME_TEXT' | translate }}
            </strong>
          </p>
          <p>{{ user()?.firstName }} {{ user()?.lastName }}</p>
        }
        <p class="mb-4 mt-6">
          <strong>{{ 'EMAIL_TEXT' | translate }}</strong>
        </p>
        <p>{{ user()?.email }}</p>
        <p class="mb-4 mt-6">
          <strong>{{ 'PHONE_NUMBER_TEXT' | translate }}</strong>
        </p>
        <p>{{ user()?.phone }}</p>
      </div>
      <lds-button [expand]="editDataButtonExpand" [size]="editDataButtonSize" (click)="toggleEditMode()">
        <p class="text-fg-white">
          {{ 'EDIT_DATA_BUTTON' | translate }}
        </p>
      </lds-button>
      <p class="text-orange-50 mt-6 cursor-pointer" (click)="changePassword()">
        <strong>{{ 'CHANGE_PASSWORD_BUTTON' | translate }}</strong>
      </p>
    </div>
  </div>
}
<router-outlet />
