<footer class="bg-[#F7F5F2] text-[#666] w-full mt-10">
  <div class="max-w-[75rem] w-full mx-auto py-4">
    <nav class="flex px-1 flex-col lg:flex-row items-center justify-start border-b border-[#EEEEEE] pb-4 mb-4">
      <div class="flex flex-row justify-center items-center space-x-4 mb-1 lg:mb-0">
        <a [href]="socialMediaLinks.facebook" class="text-[#666] hover:text-[#000]"
          ><img src="assets/images/facebook-logo.svg" alt="Facebook" class="w-4 h-4 mr-2 inline-block" />
        </a>
        <a [href]="socialMediaLinks.instagram" class="text-[#666] hover:text-[#000]"
          ><img src="assets/images/instagram-logo.svg" alt="Instagram" class="w-4 h-4 mr-2 inline-block" />
        </a>
        <a [href]="socialMediaLinks.x" class="text-[#666] hover:text-[#000]"
          ><img src="assets/images/x-logo.svg" alt="X" class="w-4 h-4 mr-2 inline-block" />
        </a>
        <a [href]="socialMediaLinks.youtube" class="text-[#666] hover:text-[#000]">
          <img src="assets/images/youtube-logo.svg" alt="YouTube" class="w-4 h-4 mr-2 inline-block" />
        </a>
      </div>
      <div class="flex flex-col lg:flex-row items-center lg:space-x-2 space-y-2 lg:space-y-0 lg:ml-auto">
        <a
          [href]="footerLinks.faq"
          class="py-1 px-2 rounded font-light hover:bg-blue-600 hover:text-fg-brand-secondary"
          target="_blank"
          >FAQ</a
        >
        <a
          [href]="footerLinks.how_we_use_your_data"
          class="py-1 px-2 rounded font-light hover:bg-blue-600 hover:text-fg-brand-secondary"
          target="_blank"
          >{{ 'HOW_WE_USE_YOUR_DATA' | translate }}</a
        >
        <a
          [href]="footerLinks.privacy_statement"
          class="py-1 px-2 rounded font-light hover:bg-blue-600 hover:text-fg-brand-secondary"
          target="_blank"
          >{{ 'PRIVACY_STATEMENT' | translate }}</a
        >
        <a
          [href]="footerLinks.terms_of_use"
          class="py-1 px-2 rounded font-light hover:bg-blue-600 hover:text-fg-brand-secondary"
          target="_blank"
          >{{ 'TERMS_OF_USE' | translate }}</a
        >
        <a
          [href]="footerLinks.contact_us"
          class="py-1 px-2 rounded font-light hover:bg-blue-600 hover:text-fg-brand-secondary"
          target="_blank"
          >{{ 'CONTACT_US' | translate }}</a
        >
        <a
          [href]="footerLinks.cookie_policy"
          class="py-1 px-2 rounded font-light hover:bg-blue-600 hover:text-fg-brand-secondary"
          target="_blank"
          >{{ 'COOKIE_POLICY' | translate }}</a
        >
      </div>
    </nav>
    <div class="text-center text-[0.8125rem] font-light">Copyright &copy; 2024 Circle K</div>
  </div>
</footer>
