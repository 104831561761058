<header class="pl-2 pr-2">
  <div class="w-full border-b border-[#EEEEEE]">
    <div class="w-full">
      <nav
        class="max-w-[75rem] w-full flex justify-end items-center mx-auto font-light uppercase text-small-screen sm:text-large-screen"
      >
        <div class="border-r border-[#EEEEEE]">
          <app-language-selector></app-language-selector>
        </div>
        <span class="py-2 px-4 border-r border-[#EEEEEE] hover:text-fg-brand-secondary cursor-default">
          {{ 'HELLO' | translate }}
          @if (region === 'eu') {
            , {{ userName() }}
          } @else {
            !
          }
        </span>
        <span (click)="logout()" class="py-2 px-4 hover:text-fg-brand-secondary cursor-pointer">
          {{ 'LOGOUT' | translate }}
        </span>
      </nav>
    </div>
  </div>
  <div class="w-full py-4 text-[13px] font-bold">
    <div class="max-w-[75rem] w-full mx-auto">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <lds-logo></lds-logo>
        </div>

        <nav class="flex items-center font-light uppercase text-small-screen sm:text-large-screen">
          <a
            [routerLink]="['/' + currentLang(), 'profile']"
            [ngClass]="{ 'text-fg-brand-secondary': isActive('/profile') }"
            class="py-2 px-4 font-bold hover:text-fg-brand-secondary"
          >
            {{ 'MY_PROFILE' | translate }}
          </a>
          <a
            [routerLink]="['/' + currentLang(), 'terms']"
            [ngClass]="{ 'text-fg-brand-secondary': isActive('/terms') }"
            class="py-2 px-4 font-bold hover:text-fg-brand-secondary"
          >
            {{ 'MY_TERMS' | translate }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</header>
