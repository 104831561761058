import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgForOf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api/api.service';
import { LoaderService } from '../components/loader/loader.service';
import { setUserData } from '../state/user/user.actions';
import { UserData } from '../state/user/user.model';
import { ModalBaseComponent } from '../components/modal-base/modal-base.component';
import { AuthService } from '../auth/auth.service';
import { finalize } from 'rxjs';
import { getUserId } from '../../helpers/getUserId';
import reportSentryError from '../../helpers/reportSentryError';
import * as Sentry from '@sentry/browser';
import {
  ButtonComponent,
  InputComponent,
  NotificationMessageComponent,
  ModalModule,
  INFO_MODAL_TYPES,
  SIZE,
  ButtonSize,
  BUTTON_EXPAND,
  NOTIFICATION_VARIANT,
} from '@circlek-global/lds-components';

interface GetUserResponseData {
  data: UserData;
}

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonComponent,
    TranslateModule,
    InputComponent,
    ReactiveFormsModule,
    NotificationMessageComponent,
    NgForOf,
    ModalModule,
    ModalBaseComponent,
  ],
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  @ViewChild(ModalBaseComponent) modalBaseComponent!: ModalBaseComponent;
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private apiService = inject(ApiService);
  private store = inject(Store);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private authService = inject(AuthService);
  loader = inject(LoaderService);
  INFO_MODAL_TYPES = INFO_MODAL_TYPES;

  @Input() editDataButtonSize: ButtonSize = SIZE.XXL;
  @Input() editDataButtonExpand: BUTTON_EXPAND = BUTTON_EXPAND.FULL;
  @Input() notificationSuccessVariant: NOTIFICATION_VARIANT = NOTIFICATION_VARIANT.SUCCESS;

  user = signal<UserData | undefined>(undefined);
  error = signal(false);

  region = this.apiService.region();

  ngOnInit() {
    this.loader.setLoader(true);
    this.getUserData();
  }

  onButtonClose() {
    this.authService.logout();
  }

  onButtonConfirm() {
    this.router.navigate([this.translate.currentLang, 'profile', 'confirm-data']);
  }

  getUserData() {
    this.loader.setLoader(true);

    const subscription = this.httpClient
      .get<GetUserResponseData>(`${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/get`, {
        withCredentials: true,
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: (responseData) => {
          this.user.set(responseData.data);

          if (responseData.data.validationRequired) {
            this.modalBaseComponent.openModal();
          }

          Sentry.setUser({ email: responseData.data.email, id: getUserId() });
          Sentry.setExtra('phone', responseData.data.phone);

          this.store.dispatch(setUserData({ data: responseData.data }));
        },
        error: (error) => {
          reportSentryError({
            error,
            customErrorName: 'Get User Data Error',
            endpointName: 'get-user-data',
          });

          console.error({ error });

          this.error.set(true);
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  tryAgain() {
    this.error.set(false);
    this.getUserData();
  }

  toggleEditMode() {
    this.router.navigate([this.translate.currentLang, 'profile', 'change-data']);
  }

  changePassword() {
    this.router.navigate([this.translate.currentLang, 'profile', 'change-password']);
  }
}
