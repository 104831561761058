export type SupportedLanguagesWithMediaLinksType = 'en' | 'lt' | 'lv' | 'et' | 'pl';

export type SocialMediaLinksType = {
  facebook: string;
  instagram: string;
  x: string;
  youtube: string;
};

export const languages = [
  { code: 'en', name: 'ENGLISH', flag: 'en.png' },
  { code: 'da', name: 'DANSK', flag: 'da.png' },
  { code: 'et', name: 'EESTI KEEL', flag: 'et.png' },
  { code: 'lt', name: 'LIETUVIŲ', flag: 'lt.png' },
  { code: 'lv', name: 'LATVIEŠU', flag: 'lv.png' },
  { code: 'no', name: 'NORSK(BOKMÅL)', flag: 'no.png' },
  { code: 'pl', name: 'POLSKI', flag: 'pl.png' },
  { code: 'sv', name: 'SVENSKA', flag: 'sv.png' },
];

export const footerLinks = {
  faq: 'https://id.circlekeurope.com/#/legal/faq',
  how_we_use_your_data: 'https://id.circlekeurope.com/#/legal/dataUse',
  privacy_statement: 'https://id.circlekeurope.com/#/legal/privacy-2022-01',
  terms_of_use: 'https://id.circlekeurope.com/#/legal/terms',
  contact_us: 'https://www.circlek.eu/contact-us',
  cookie_policy: 'https://id.circlekeurope.com/#/legal/cookies',
};

export const socialMediaLinks: Record<SupportedLanguagesWithMediaLinksType, SocialMediaLinksType> = {
  en: {
    facebook: 'https://www.facebook.com/CircleKStores/',
    instagram: 'https://www.instagram.com/circlekstores/',
    x: 'https://x.com/circlekstores/',
    youtube: 'https://www.youtube.com/channel/UC7kPuM7FmDnHjJHvJIPJP_Q',
  },
  lt: {
    facebook: 'https://www.facebook.com/CircleKLietuva/?ref=br_rs',
    instagram: 'https://www.instagram.com/circlek_lietuva/?hl=en',
    x: 'https://x.com/circlekstores/',
    youtube: 'https://www.youtube.com/channel/UC7kPuM7FmDnHjJHvJIPJP_Q',
  },
  lv: {
    facebook: 'https://www.facebook.com/circleklv/',
    instagram: 'https://www.instagram.com/circleklatvija/',
    x: 'https://twitter.com/CircleKLatvija/',
    youtube: 'https://www.youtube.com/channel/UC7kPuM7FmDnHjJHvJIPJP_Q',
  },
  et: {
    facebook: 'https://www.facebook.com/CircleKeesti/',
    instagram: 'https://www.instagram.com/circlekeesti/',
    x: 'https://twitter.com/CircleKLatvija/',
    youtube: 'https://www.youtube.com/channel/UC7kPuM7FmDnHjJHvJIPJP_Q',
  },
  pl: {
    facebook: 'https://www.facebook.com/CircleKPolska/',
    instagram: 'https://www.instagram.com/circlek_polska/',
    x: 'https://twitter.com/circlek_polska',
    youtube: 'https://www.youtube.com/channel/UC7kPuM7FmDnHjJHvJIPJP_Q',
  },
};
