<mat-select
  [(value)]="selectedLanguage"
  (selectionChange)="onLanguageChange($event)"
  class="text-[0.8125rem] hover:text-fg-brand-secondary h-[2rem] text-[#000000] flex items-center pl-2 pr-2 uppercase font-light text-small-screen sm:text-large-screen"
>
  @for (language of languages; track language.code) {
    <mat-option [value]="language.code" class="flex items-center text-[12px] uppercase">
      <div class="flex items-center py-[10px] px-[20px] w-full">
        <img src="assets/images/{{ language.flag }}" alt="{{ language.name }} flag" class="w-4 h-4 mr-2 inline-block" />
        <span class="inline-block">{{ language.name }}</span>
      </div>
    </mat-option>
  }
</mat-select>
