import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  footerLinks,
  socialMediaLinks,
  SocialMediaLinksType,
  SupportedLanguagesWithMediaLinksType,
} from '../../../helpers/constants';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  footerLinks = footerLinks;
  socialMediaLinks!: SocialMediaLinksType;

  private translate = inject(TranslateService);
  private langChangeSubscription!: Subscription;

  ngOnInit() {
    this.updateSocialMediaLinks(this.translate.currentLang as SupportedLanguagesWithMediaLinksType);
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event) => {
      this.updateSocialMediaLinks(event.lang as SupportedLanguagesWithMediaLinksType);
    });
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private updateSocialMediaLinks(lang: SupportedLanguagesWithMediaLinksType) {
    this.socialMediaLinks = socialMediaLinks[lang] || socialMediaLinks.en;
  }
}
