import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { selectUserData } from '../../state/user/user.selectors';
import { UserData } from '../../state/user/user.model';
import { Store } from '@ngrx/store';
import { ApiService } from '../../api/api.service';
import { LogoComponent } from '@circlek-global/lds-components';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, LanguageSelectorComponent, LogoComponent, TranslateModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentRoute: string = '';
  private router = inject(Router);
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private store = inject(Store);
  private translate = inject(TranslateService);

  region = this.apiService.region();

  userName = signal<string>('');
  currentLang = signal('en');
  langChangeSub!: Subscription;

  constructor() {
    this.langChangeSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang.set(event.lang);
    });
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.currentRoute = this.router.url;
    });

    this.store.select(selectUserData).subscribe((userData: UserData | null) => {
      this.userName.set(userData?.firstName || '');
    });
  }

  logout() {
    return this.authService.logout();
  }

  isActive(route: string): boolean {
    const normalizedCurrentRoute = this.normalizeRoute(this.currentRoute);
    const normalizedRoute = this.normalizeRoute(route);
    return normalizedCurrentRoute === normalizedRoute;
  }

  private normalizeRoute(route: string): string {
    return route.replace(/^\/[a-z]{2}\//, '/');
  }

  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }
}
